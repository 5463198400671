import React, { useState } from 'react'
import useDarkSide from '../hook/useDarkSide';
import { FaMoon, FaSun, FaBars } from 'react-icons/fa'
import { NeuroButton, ThemeSwitch } from '../components/modules'
import { useDispatch } from 'react-redux';
import { setCurrentMode } from '../counters/currentSlice';
function NavBar() {
    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState(colorTheme === "light" ? true : false);
    const [currentTheme, setCurrentTheme] = useState(true);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme);
        setDarkSide(checked);
        setCurrentTheme(!currentTheme);
        dispatch(setCurrentMode(colorTheme));
    };


    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const scrollToElement = (targetId) => {
        setMenuOpen(!isMenuOpen);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <header className="flex flex-row justify-between px-6 py-2 bg-light-primary dark:bg-dark-secondary z-100">
            <div className='flex items-center'>

                {
                    colorTheme === 'light' ? <img
                        src="assets/website-logo/navbar-dark.png"
                        width="65"
                        alt="Maxus Logo"
                    /> : <img
                        src="assets/website-logo/navbar-light.png"
                        width="65"
                        alt="Maxus Logo"
                    />
                }
            </div>
            {/* Regular navigation for screens larger than medium (md) */}
            <div className="hidden md:flex flex-row mx-auto items-center gap-4">
                <NeuroButton targetId={"home"} color="white" normal={true} content="Home" dark_color="black" wx={30} hy={10} px={4} py={2} />
                <NeuroButton targetId={"qualification"} color="white" normal={true} content="Qualification" dark_color="black" wx={30} hy={10} px={4} py={2} />
                {/* <img
                    src="assets/website-logo/header.png"
                    width="60"
                    alt="Maxus Logo"
                /> */}
                <NeuroButton targetId={"projects"} color="white" normal={true} content="Projects" dark_color="black" wx={30} hy={10} px={4} py={2} />
                <NeuroButton targetId={"about"} color="white" normal={true} content="About" dark_color="black" wx={30} hy={10} px={4} py={2} />
            </div>

            {/* Burger icon for medium screens and smaller */}
            <div className="flex justify-between items-center">
                <div className=" md:hidden">
                    <NeuroButton onClick={toggleMenu}
                        content={<FaBars />}
                        dark_color="black" color="white" wx={30} hy={10} px={4} py={2}
                    />
                </div>
                <div className="ml-2 ">
                    <NeuroButton onClick={toggleDarkMode}
                        content={colorTheme === 'light' ? <FaMoon /> : <FaSun />}
                        dark_color="black" color="white" wx={30} hy={10} px={4} py={2} />
                </div>
            </div>

            {/* Mobile menu */}
            {isMenuOpen && (
                <div id="dropdownNavbar" class="md:hidden bg-white absolute right-5 top-10 text-base z-10 list-none divide-x divide-gray-100 rounded shadow my-4 w-44">
                    <ul class="py-1 z-10000" aria-labelledby="dropdownLargeButton">
                        <li>
                            <a onClick={() => scrollToElement("home")} class="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Home</a>
                        </li>
                        <li>
                            <a onClick={() => scrollToElement("qualification")} class="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Qualifications</a>
                        </li>
                        <li>
                            <a onClick={() => scrollToElement("projects")} class="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Project</a>
                        </li>
                        <li>
                            <a onClick={() => scrollToElement("about")} class="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">About</a>
                        </li>
                    </ul>
                </div>
            )}


        </header>
    )
}

export default NavBar