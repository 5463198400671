import React from "react";
// import Icons from "../icons/icons.svg"; // Path to your icons.svg
import Icons from '../../icons/icons.svg'
import PropTypes from 'prop-types';

const Icon = ({ className, name, color, dark_color, size, href }) => (
  <img className="icon mx-auto w-[25px] h-[25px] md:w-[35px] md:h-[35px]" src={`assets/icons/${name}-logo.svg`}  alt="Logo" />

);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  // color: PropTypes.string,
  size: PropTypes.number
};

export default Icon;