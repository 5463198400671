import React from 'react'
import { Education, Stack } from '../components/modules'
import AnimatedIconsList from '../components/General/AnimatedIconsList'
import { motion } from 'framer-motion'
import data from '../data/icons'
const Qualification = () => {
    return (
        <section
            className="dark:bg-black dark:text-white lg:px-52 lg:py-12 md:px-20 md:py-8 sm:px-12 sm:py-6 px-8 py-6 z-1000"
            id="qualification"
            >
            <div class="flex flex-col gap-3 place-content-center items-center">
                <h1 class="text-4xl text-light-primary dark:text-dark-primary font-bold mt-4">Qualifications</h1>
            </div>
            <div className="flex flex-shrink-0 absolute left-0 right-50 z-0">
                    <div className="w-[300px] h-[300px] circle-shadow" id="full" />
                </div>
            <div className="flex-shrink-0 absolute hidden md:flex right-[122px] z-0">
                <div className="w-[160px] h-[160px] circle-shadow" />
            </div>
            <div className="py-4 ">
                <Education />
            </div>

            <div className='flex place-items-center z-1000'>
                <AnimatedIconsList
                    elements={data.stackIcons}
                    element_number={data.stackIcons.length}

                />
            </div>

        </section>
    )
}

export default Qualification