import React from 'react'
import { motion } from 'framer-motion'
import { Icon } from '../modules'
const AnimatedIcon = ({ name, color, size, src }) => {
  return (
    <motion.div
    className="flex justify-center items-center"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <a href={src} target="_blank" rel="noopener noreferrer">
        <Icon
          name={name}
          size={size}
          color={color}
          src={src}
        />
      </a>
    </motion.div>
  )
}

export default AnimatedIcon