import React, { useState, useMemo } from 'react'
import { NeuroButton } from '../modules'
import Achievements from './Achievements'
import { motion } from 'framer-motion'
import data from '../../data/qualifications'
const Education = () => {
    const [selectedTab, setSelectedTab] = useState('internship');
    const education = data.education;

    const type = useMemo(() => education.filter(
        (edu) => edu.type === selectedTab
    ), [selectedTab]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="z-10" className="flex flex-col relative h-full">
            <div class="text-md font-medium text-center text-gray-400 ">
                <ul class="hidden xl:flex flex-wrap justify-evenly -mb-px cursor-pointer">
                    <li class="mr-2">
                        <a onClick={() => setSelectedTab('foundation')} class={`inline-block ${selectedTab === 'foundation' ? 'text-light-primary dark:text-dark-primary border-b-2 border-gray-700' : ''} p-4  rounded-t-lg hover:text-blue-600 dark:hover:text-dark-secondary`}>Foundation</a>
                    </li>
                    <li class="mr-2">
                        <a onClick={() => setSelectedTab('bachelor')} class={`inline-block ${selectedTab === 'bachelor' ? 'text-light-primary dark:text-dark-primary border-b-2 border-gray-700' : ''} p-4  rounded-t-lg hover:text-blue-600 dark:hover:text-dark-secondary`}>Degree</a>
                    </li>
                    <li class="mr-2">
                        <a onClick={() => setSelectedTab('internship')} class={`inline-block ${selectedTab === 'internship' ? 'text-light-primary dark:text-dark-primary border-b-2 border-gray-700' : ''} p-4  rounded-t-lg hover:text-blue-600 dark:hover:text-dark-secondary`}>Internship</a>
                    </li>

                </ul>
            </div>
            <div className="flex xl:hidden">
                <select
                    className='mx-auto text-blue-500 dark:text-orange-500 text-center w-4/5 mt-4 bg-gray-200 border border-gray-300 md:text-lg text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    value={selectedTab}
                    onChange={(e) => setSelectedTab(e.target.value)}
                >
                    <option value="foundation">Foundation</option>
                    <option value="bachelor">Bachelor's Degree</option>
                    <option value="internship">Internship</option>
                </select>
            </div>
            <div className="h-full">
                {selectedTab === 'foundation' && (
                    <motion.div

                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <Achievements type={type} />
                    </motion.div>
                )}
                {selectedTab === 'bachelor' && (
                    <motion.div

                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <Achievements type={type} />
                    </motion.div>
                )}
                {selectedTab === 'internship' && (
                    <motion.div

                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <Achievements type={type} />
                    </motion.div>
                )}
            </div>
        </motion.div >
    );
}

export default Education