import React from 'react'

const NeuroButton = ({ type, normal, onClick, content, px, py, wx, hy, color, dark_color, size, targetId, disabled }) => {
    const scrollToElement = () => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <button
            type={type ? type : ""}
            disabled={disabled}
            onClick={!normal ? onClick : scrollToElement}
            className={`py-${py} px-${px} w-${wx} h-${hy} text-${color} text-${size} dark:text-${dark_color} rounded-full  shadow hover:text-[#E1E5FA] dark:hover:text-white hover:shadow-[#E1E5FA] transition ease-in-out dark:hover:shadow-white`}>
            {content}
        </button>

    )
}

export default NeuroButton