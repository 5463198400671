import { createSlice } from "@reduxjs/toolkit";
// handle state change of number between
const initialState = {
    currentStyle: '',
    currentSlide: 0,
    currentMode: 'light',
}

const currentSlice = createSlice({
    name: 'current',
    initialState,
    reducers: {
        setCurrentStyle: (state, action) => {
            // read what you get from the dispatch first 
            const currentStyle = action.payload;
            state.currentStyle = currentStyle;
        },
        setCurrentSlide: (state, action) => {
            const currentSlide = action.payload;
            state.currentSlide = currentSlide;
        },
        setCurrentMode: (state, action) => {
            const currentMode = action.payload; 
            state.currentMode = currentMode;
        }

    }
})

export const { setCurrentStyle, setCurrentSlide, setCurrentMode } = currentSlice.actions;

export const selectCurrentStyle = (state) => state.current.currentStyle;
export const selectCurrentSlide = (state) => state.current.currentSlide;
export const selectCurrentMode = (state) => state.current.currentMode;
export default currentSlice.reducer