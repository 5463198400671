import React, { useState, useEffect, useRef } from 'react';
import { NeuroButton, TextToSpeech } from '../modules';

const MAX_INPUT_LENGTH = 50;
const CHARACTER_DELAY = 50; // Adjust delay in milliseconds as needed

const Chat = () => {
    const [inputValue, setInputValue] = useState('');
    const [response, setResponse] = useState('');
    const [answer, setAnswer] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(null);
    const [lastClickTimestamp, setLastClickTimestamp] = useState(null);
    const inputRef = useRef();
    const chatbot_key = process.env.REACT_APP_TITAN_KEY;

    const [error, setError] = useState('');

    useEffect(() => {
        // Check if there's a stored timestamp
        const storedTimestamp = localStorage.getItem('lastClickTimestamp');
        if (storedTimestamp) {
            const elapsedTime = Date.now() - parseInt(storedTimestamp, 10);
            if (elapsedTime < 10000) {
                // If less than 1 minute has passed, disable the button
                setDisabled(true);
                const remainingTime = 10000 - elapsedTime;
                setSecondsLeft(Math.ceil(remainingTime / 1000));
                console.log(secondsLeft)
                const intervalId = setInterval(() => {
                    setSecondsLeft(prevSeconds => {
                        if (prevSeconds > 1) {
                            return prevSeconds - 1;
                        } else {
                            clearInterval(intervalId);
                            setDisabled(false);
                            return null;
                        }
                    });
                }, 1000);
                return () => clearInterval(intervalId);
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const trimmedValue = event.target.value;
        setInputValue(trimmedValue.slice(0, MAX_INPUT_LENGTH)); // Enforce max length
    };

    const handleSubmit = async () => {
        if (inputValue.trim() === '') {
            setError('Input box cannot be empty');
            return;
        }

        if (inputValue.trim().toLowerCase().indexOf('maxus') === -1) {
            setError("Please ask questions related to Maxus, and be precise. For example, 'How is Maxus?', 'What is Maxus CGPA'");
            return;
        }

        setIsLoading(true);
        setResponse('');
        setError(''); // Clear any previous errors
        setDisabled(true);
        // Store the current timestamp
        const timestamp = Date.now();
        localStorage.setItem('lastClickTimestamp', timestamp.toString());
        setSecondsLeft(10);

        try {
            const response = await fetch(chatbot_key, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ question: inputValue }),
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.status}`);
            }

            const responseData = await response.json();
            const cleanedResponse = responseData.body
                .trim() // Trim leading and trailing whitespace
                .replace(/\\([\"\\])/g, '$1') // Remove escape characters
                .replace(/^"|"$/g, '') // Remove double quotes from start and end
                .replace(/^" |"$|\\ud83d\\ude09"|\\n/g, ''); // Remove additional unwanted characters

            setResponse(cleanedResponse);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('An error occurred while processing your input. Please try again.');
        } finally {
            setIsLoading(false);
            const intervalId = setInterval(() => {
                setSecondsLeft(prevSeconds => {
                    if (prevSeconds > 1) {
                        return prevSeconds - 1;
                    } else {
                        clearInterval(intervalId);
                        setDisabled(false);
                        return null;
                    }
                });
            }, 1000);
        }
    };

    useEffect(() => {
        if (response) {
            setAnswer('');
            displayResponseWithDelay(response);
        }
    }, [response]); // Run only when response changes

    const displayResponseWithDelay = async (receivedString) => {
        const fakeResponse = receivedString; // Replace with your actual response
        for (let i = 0; i < fakeResponse.length; i++) {
            await new Promise(resolve => setTimeout(resolve, CHARACTER_DELAY)); // Delay for 100 milliseconds
            setAnswer(prevResponse => prevResponse + fakeResponse[i]); // Update response character by character
        }
    };

    return (
        <div className="container mx-auto p-4 justify-content-center">
            <h2 className="text-2xl text-light-primary dark:text-dark-primary text-center font-bold mb-4">Maxus Knowledge Base</h2>
            <p className="text-light-secondary dark:text-dark-secondary mb-6">Enter a question about Maxus.</p>
            <div className="flex flex-col gap-2 ">
                <div className="flex items-center relative">
                    <input
                        type="text"
                        id="filteredInput"
                        value={inputValue}
                        onChange={handleInputChange}
                        maxLength={MAX_INPUT_LENGTH}
                        disabled={isLoading}
                        className="rounded-md border dark:bg-black border-gray-300 w-full px-2 py-8 focus:ring-blue-500 focus:ring-opacity-50"
                    />
                    <div className='flex flex-row gap-2 h-6 absolute inset-y-2 left-2'>
                        <span className="h-8 dark:text-white text-gray-600">INPUT</span>
                        <TextToSpeech text={response} disabled={!response} />
                    </div>
                    <span className="absolute inset-y-2 right-2 h-8 dark:text-gray-100 text-gray-600">Enter your question here</span>
                </div>
                <NeuroButton wx={20} wy={10} px={4} py={2} content={isLoading ? "Loading..." : (disabled ? `🛠 ${secondsLeft}s remaining` : "🤖 Ask")} onClick={handleSubmit} disabled={disabled} />
                <div className={`${isLoading ? 'loading' : 'loaded'} w-full relative rounded-md border border-gray-300 px-2 py-8`}>
                    <span className="absolute inset-y-2 left-2   text-gray-600">OUTPUT</span>
                    <span className="absolute inset-y-2 right-2 md:block hidden  text-gray-600">Answer from knowledge base</span>
                    {response && <div dangerouslySetInnerHTML={{ __html: answer }} />}
                    {error && <p className="text-red-500 text-sm mt-4">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default Chat;
