

const Loading = () => {
    return (
        <div className="centered">
            <div class="typing-effect">
                welcome..
            </div>
        </div>
    );
}

export default Loading;