const data =
{
    mlProjects: [
        {
            title: "LLMOps",
            description: `The emergence of Generative AI, alongside the development of Large Language Models, represents a significant milestone with transformative implications across various domains. Recognizing this potential for revolutionary change, my primary focus lies in understanding and harnessing the capabilities of this technology. I hold a firm belief in the profound impact that Generative AI can have on reshaping numerous aspects of our world, which motivates my dedication to learning and mastering its principles.`,
            imageUrl: "/assets/ml_carousel/amazon-bedrock-agent.png",
        },
        {
            title: "Sensor Fusion using Machine Learning for Glass Detection (FYP)",
            description: `My Final Year Project compared machine learning to traditional mathematical modeling, covering automated data collection, model training, optimization, and real-world testing in robotic navigation. 
            The robot was integrated with ROS navigation package and can effectively carry out path planning, avoid static and dynamic obstacles using predictions from the model alone.
            The video showcases the robot planning paths and halting upon encountering glass.
            `,
            imageUrl: "/assets/ml_carousel/proposed-pipeline.png",
        },
        {
            title: "BedrockChat",
            description: `An ongoing project involving an RAG chatbot web-app that can answer questions related to 
            to the uploaded file.  The chatbot is built on top of AWS Services and allows user to select all
            the Amazon Bedrock models currently available. User uploads a pdf or docx which is then converted to FAISS embeddings
            and stored in S3 bucket. Subsequently, the user can ask questions related to the document with model of choice and the chatbot will provide the answer.`,
            // description: `The project involves training a style transfer model from scratch
            // and deploying the model using FastAPI, and represents one of the first models I 
            // have successfully deployed from scratch after training in a notebook.`,
            imageUrl: "/assets/ml_carousel/bedrock_chat.png",
        },
    ],
    webProjects: [
        {
            title: "Gen AI Stock Recommendation App",
            description: `A generative AI app built to explain to provide an explanation for the numbers present.
            The frontend is fully React Native, while the backend is powered by OpenAI GPT-4 large language model. 
            A Langchain agent is incorporated that helps the user ask about information related to Hong Leong Bank and 
            financial related questions. The agent is also capable of real-time portfolio optimization.
            `,
            imageUrl: "/assets/website_carousel/wealthblend.jpg",
        },
        {
            title: "Uber App Clone",
            description: `First mobile app using React Native developed  that mimcxs the Uber app. The app is capable of real-time location tracking, ride hailing and additional
            features such as QR Code discount.`,
            imageUrl: "/assets/website_carousel/uber.jpeg",
        },
        {
            title: "Multimedia Assignment",
            description: "My first exposure to website development in my course. Everything was done using HTML, CSS and Javascript.",
            imageUrl: "/assets/website_carousel/geoloco_home.png",
        },
    ],
    iconList: [
        {
            name: 'react',
            color: 'aqua',
            size: 40
        },
        {
            name: 'redux',
            color: 'purple',
            size: 40
        },
        {
            name: 'tailwind-css',
            color: 'aqua',
            size: 40
        },
        {
            name: 'fastapi',
            color: 'green',
            size: 40
        },
        {
            name: 'nodejs',
            color: 'green',
            size: 40
        },
        {
            name: 'nextjs',
            color: 'green',
            size: 40
        },

    ],
}

export default data