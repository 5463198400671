import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import AnimatedIcon from './AnimatedIcon';

const AnimatedIconsList = ({ elements, element_number }) => {
    const gridClass = `grid grid-cols-${element_number + 1} width-[${element_number* 50}px]`
    // const gridClass = `grid grid-cols-7 width-[${element_number* 50}px]`


    return (
        <div className={`${gridClass} z-10 mx-auto gap-2 md:gap-4 py-2 border-b-4 border-spacing-2 justify-items-center`} id="list-container">
            <div className='flex col-span-1 justify-center items-center mx-auto '>
                <h1 className="text-center md:text-lg text-md text-light-primary dark:text-red-900">TECH</h1>
            </div>

            {elements.map((element, index) => (
                <motion.div
                    key={index}
                    className="flex mx-auto justify-content-center duration-300"
                >
                    <AnimatedIcon
                        name={element.name}
                        src={element.src}
                        color={element.color}
                        size={35}
                    />
                </motion.div>
            ))}
        </div>
    );
};

export default AnimatedIconsList;
