import React, { useEffect, useState } from 'react';
import './App.css';
import { NavBar } from './components/modules';
import './css/styles.css';
import './css/Loading.css';
import Introduction from './screens/Introduction';
import Projects from './screens/Projects';
import Qualification from './screens/Qualification';
import Footer from './screens/Footer';
import Loading from './screens/Loading';
import Divider from './components/Divider';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);

  });


  if (loading) {
    return <Loading />
  }
  return (
    <div>
      <NavBar />
      <Introduction />
      <Projects />
      <Qualification />
      <Divider />
      <div className="bg-light-primary dark:bg-dark-secondary">
        <Footer />
      </div>
    </div >
  )
}


export default App;
