import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Stage, Layer, Line } from 'react-konva';
import { NeuroButton } from '../modules';

const Canvas = ({ height, width }) => {
    const [tool, setTool] = useState('pen');
    const [lines1, setLines1] = useState([]);
    const [lines2, setLines2] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [prediction, setPrediction] = useState('');

    const [secondsLeft, setSecondsLeft] = useState(null);
    const [lastClickTimestamp, setLastClickTimestamp] = useState(null);

    const canvasKey = process.env.REACT_APP_CANVAS_KEY;

    const stageRef1 = useRef(null);
    const stageRef2 = useRef(null);
    // required for mutable values
    const isDrawing = useRef(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        // Check if there's a stored timestamp
        const storedTimestamp = localStorage.getItem('lastClickTimestamp');
        if (storedTimestamp) {
            const elapsedTime = Date.now() - parseInt(storedTimestamp, 10);
            if (elapsedTime < 10000) {
                // If less than 1 minute has passed, disable the button
                setDisabled(true);
                const remainingTime = 10000 - elapsedTime;
                setSecondsLeft(Math.ceil(remainingTime / 1000));
                const intervalId = setInterval(() => {
                    setSecondsLeft(prevSeconds => {
                        if (prevSeconds > 1) {
                            return prevSeconds - 1;
                        } else {
                            clearInterval(intervalId);
                            setDisabled(false);
                            return null;
                        }
                    });
                }, 1000);
                return () => {
                    clearInterval(intervalId);
                    window.removeEventListener('resize', handleResize);
                };
            }
        }
    }, []);

    // handling dynamic width 
    const canvasWidth = windowWidth >= 780 ? 180 : (windowWidth / 768) * 225;

    const handlePrediction = async () => {
        const canvasData1 = stageRef1.current.toDataURL();
        const canvasData2 = stageRef2.current.toDataURL();

        setIsLoading(true);
        setError(''); // Clear any previous errors
        setDisabled(true);
        // Store the current timestamp
        const timestamp = Date.now();
        localStorage.setItem('lastClickTimestamp', timestamp.toString());
        setSecondsLeft(15);

        try {
            const response = await fetch(canvasKey, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ image1: canvasData1, image2: canvasData2 }),
            });
            const data = await response.json();
            setPrediction(data.body);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('An error occurred while processing your input. Please try again.');
        } finally {
            setIsLoading(false);
            const intervalId = setInterval(() => {
                setSecondsLeft(prevSeconds => {
                    if (prevSeconds > 1) {
                        return prevSeconds - 1;
                    } else {
                        clearInterval(intervalId);
                        setDisabled(false);
                        return null;
                    }
                });
            }, 1000);
        }
    };

    const handleMouseDown = (canvasLines, setCanvasLines) => (e) => {
        e.target.preventDefault();
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setCanvasLines([...canvasLines, { tool, points: [pos.x, pos.y] }]);
    };

    const handleMouseMove = (canvasLines, setCanvasLines) => (e) => {
        // no drawing - skipping
        e.target.preventDefault();
        if (!isDrawing.current) {
            return;
        }

        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = canvasLines[canvasLines.length - 1];
        lastLine.points = lastLine.points.concat([point.x, point.y]);
        canvasLines.splice(canvasLines.length - 1, 1, lastLine);

        setCanvasLines(canvasLines.concat());
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };


    const clearCanvas = () => {
        setLines1([]);
        setLines2([]);
        setPrediction('');
    };

    return (
        <div className='container  px-12'>
            <div className="grid xl:grid-cols-3 grid-cols-2 mt-4  md:gap-8 gap-4 mx-auto">
                <div className="grid grid-flow-row gap-8 justify-center items-center">
                    <Fragment
                    >
                        <Stage
                            width={canvasWidth}
                            height={height}
                            onMouseDown={handleMouseDown(lines1, setLines1)}
                            onMousemove={handleMouseMove(lines1, setLines1)}
                            onMouseup={handleMouseUp}
                            onTouchstart={handleMouseDown(lines1, setLines1)}
                            onTouchmove={handleMouseMove(lines1, setLines1)}
                            onTouchend={handleMouseUp}
                            ref={stageRef1}
                            className={`bg-gray-600 dark:bg-gray-300 w-[${width}px] h-[${height}px]`}
                        >
                            <Layer
                            >
                                {lines1.map((line, i) => (
                                    <Line
                                        key={i}
                                        points={line.points}
                                        stroke="white"
                                        strokeWidth={line.tool === 'eraser' ? 25 : 10}
                                        tension={0.5}
                                        lineCap="round"
                                        lineJoin="round"
                                        globalCompositeOperation={
                                            line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                        }
                                    />
                                ))}
                            </Layer>
                        </Stage>
                    </Fragment>
                </div>
                <div className="grid grid-flow-row gap-8 justify-center items-center">
                    <Fragment>
                        <Stage
                            width={canvasWidth}
                            height={height}
                            onMouseDown={handleMouseDown(lines2, setLines2)}
                            onMousemove={handleMouseMove(lines2, setLines2)}
                            onMouseup={handleMouseUp}
                            onTouchstart={handleMouseDown(lines2, setLines2)}
                            onTouchmove={handleMouseMove(lines2, setLines2)}
                            onTouchend={handleMouseUp}
                            ref={stageRef2}
                            className={`bg-gray-600 dark:bg-gray-300 w-[${width}px] h-[${height}px]`}
                        >
                            <Layer
                            >
                                {lines2.map((line, i) => (
                                    <Line
                                        key={i}
                                        points={line.points}
                                        stroke="white"
                                        strokeWidth={line.tool === 'eraser' ? 25 : 10}
                                        tension={0.5}
                                        lineCap="round"
                                        lineJoin="round"
                                        globalCompositeOperation={
                                            line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                        }
                                    />
                                ))}
                            </Layer>
                        </Stage>
                    </Fragment>
                </div>
                <div className={`${isLoading ? 'loading' : 'loaded'} shadow-lg  shadow-black xl:col-span-1 col-span-2 w-52 h-52  mx-auto border-2 p-2 text-center`}>
                    <h1 className="text-[120px] font-bold text-center justify">{prediction}</h1>
                </div>
            </div>

            <div className='flex flex-row gap-2 h-10  mx-auto justify-between mt-6'>
                <NeuroButton wx={20} wy={10} px={4} py={2} content={isLoading ? "Loading..." : (disabled ? `${secondsLeft}s` : "Predict")} disabled={disabled} onClick={handlePrediction} />
                <div className="md:flex-row flex-col  gap-2 md:flex hidden">
                    <NeuroButton wx={20} wy={10} px={4} py={2} dark_color={tool === 'pen' ? 'orange-500' : ''} color={tool === 'pen' ? 'blue-500' : ''} content="Pen" onClick={() => { setTool('pen') }} />
                    <NeuroButton wx={20} wy={10} px={4} py={2} dark_color={tool === 'eraser' ? 'orange-500' : ''} color={tool === 'eraser' ? 'blue-500' : ''} content="Eraser" onClick={() => { setTool('eraser') }} />
                </div>
                <NeuroButton wx={20} wy={10} px={4} py={2} content="Clear" onClick={clearCanvas} />
            </div>
            <div className='md:p-4 mt-10  font-semibold md:text-md text-sm leading-relaxed text-center'>Model powered by a Vision Transformer and deployed using AWS Sagemaker (under construction).</div>
        </div>
    );
}

export default Canvas