import React, { useState } from 'react'
import { UploadFile, Canvas, StylesSelector, NeuroButton, IconsList, ImageSlider } from '../components/modules'
import { motion } from 'framer-motion'
import data from '../data/icons'
import AnimatedIconsList from '../components/General/AnimatedIconsList'
import DataAnalytics from './DataAnalytics'
import projects from '../data/data'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentSlide, setCurrentSlide } from '../counters/currentSlice'
import WebApp from './WebApp'
import MachineLearning from './MachineLearning'
// import StylesSelector from '../components/StylesSelector'
const Projects = () => {
    const [selectedTab, setSelectedTab] = useState('ml');
    const currentSlide = useSelector(selectCurrentSlide);   
    const dispatch = useDispatch();
    

    const setTab = (tab) => {
        dispatch(setCurrentSlide(0));
        setSelectedTab(tab);

    }

    return (
        <section className="dark:bg-black dark:text-white  md:px-16 px-8" id="projects">
            <div class="gap-3 items-center justify-center">
                
                <div className="flex flex-shrink-0 md:block hidden absolute left-50 right-0 z-0">
                    <div className="w-[300px] h-[300px] circle-shadow" id="full" />
                </div>
                {/* <h1 className="text-4xl text-light-primary dark:text-dark-primary font-bold">Projects</h1> */}
                <div className="flex flex-col relative">
                    <div className="flex flex-col text-md font-medium text-center text-gray-400 z-10">
                        <div className="font-semibold text-4xl text-light-primary dark:text-dark-primary">Projects</div>
                        <ul class="hidden xl:flex flex-wrap justify-evenly -mb-px cursor-pointer pt-6">
                            <li class="mr-2">
                                <a onClick={() => setTab('ml')} class={`inline-block ${selectedTab === 'ml' ? 'text-light-primary dark:text-dark-primary border-b-2 border-gray-700' : ''} p-4 rounded-t-lg hover:text-blue-600 dark:hover:text-dark-secondary`}>Machine Learning</a>
                            </li>
                            <li class="mr-2">
                                <a onClick={() => setTab('da')} class={`inline-block ${selectedTab === 'da' ? 'text-light-primary dark:text-dark-primary border-b-2 border-gray-700' : ''} p-4  rounded-t-lg hover:text-blue-600 dark:hover:text-dark-secondary`}>Data Analytics</a>
                            </li>
                            <li class="mr-2">
                                <a onClick={() => setTab('app')} class={`inline-block ${selectedTab === 'app' ? 'text-light-primary dark:text-dark-primary border-b-2 border-gray-700' : ''} p-4 rounded-t-lg hover:text-blue-600 dark:hover:text-dark-secondary`}>Web/App Development</a>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="flex xl:hidden z-10">
                    <select
                        className='mx-auto z-10 text-blue-500 dark:text-orange-500 text-center w-4/5 mt-4 bg-gray-200 border border-gray-300 md:text-lg text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        value={selectedTab}
                        onChange={(e) => setTab(e.target.value)}
                    >
                        <option value="ml">Machine Learning</option>
                        <option value="da">Data Analytics</option>
                        <option value="app">Mobile & Web Development</option>
                    </select>
                </div>
            </div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
                className="z-10">
                {
                    selectedTab === 'da' && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="h-full justify-center z-100">

                            <DataAnalytics />

                        </motion.div>
                    )
                }
                {
                    selectedTab === 'ml' && (
                        <MachineLearning />
                    )
                }
                {
                    selectedTab === 'app' && (
                        <WebApp />
                    )
                }
            </motion.div>

        </section >
    )
}

export default Projects