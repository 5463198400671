import React from 'react'
import { FaWhatsapp, FaEnvelope, FaGithub, FaLinkedin, FaGit } from 'react-icons/fa';
import { selectCurrentMode, setCurrentMode, setCurrentStyle } from '../counters/currentSlice';
import { useSelector } from 'react-redux';
import { SocialLink } from '../components/modules';
const Footer = () => {
    const currentMode = useSelector(selectCurrentMode);

    return (
        <footer className="relative p-12 md:pt-20 pt-32  md:top-[-250px] top-[-300px] md:h-[400px] h-[360px] bg-light-primary dark:bg-dark-secondary text-white dark:text-black">
            <div className="flex flex-col gap-2 col-span-1 pt-[200px] justify-center place-items-center">
                {
                    currentMode === 'light' ?
                        <img src="assets/website-logo/footer-light.png" alt="" className='h-[80px] object-contain w-full mt-4' />
                        : <img src="assets/website-logo/footer-dark.png" alt="" className='h-[80px]  object-contain w-full mt-4' />
                }
                <h1 className='text-center md:text-4xl text-2xl mt-4 font-weight-50'>Let's build something meaningful together.</h1>

                <div className='flex flex-row gap-8 mt-8'>
                    <a href="mailto:maxusj27@gmail.com" target="_blank" rel="noopener noreferrer">
                            <FaEnvelope size={40} className="icon md:text-2xl lg:text-3xl" id="linkedin" />
                        </a>
                        <a href="https://www.linkedin.com/in/maxus-jaisi-b4b41322b/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin size={40} className="icon hover:fill-blue-400 md:text-2xl lg:text-3xl" id="linkedin" />
                        </a>
                        <a href="https://github.com/MaxusJ27" target="_blank" rel="noopener noreferrer">
                            <FaGithub size={40} className="icon hover:fill-green-600 md:text-2xl lg:text-3xl" id="github" />
                        </a>
                        <a href="httzzzzps://github.com/MaxusJ27" target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp size={40} className="icon hover:fill-green-400 md:text-2xl lg:text-3xl" id="whatsapp" />
                        </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer