import React, { useState } from 'react'
import { Dashboard, Pipeline, IconsList, NeuroButton } from '../components/modules'
import data from '../data/icons'
import AnimatedIconsList from '../components/General/AnimatedIconsList'
const DataAnalytics = () => {
  const dataAnalyticsIcons = data.dataAnalyticsIcons;

  const [dashboardVisible, setDashboardVisible] = useState(false);

  const toggleDashboard = () => {
    setDashboardVisible(!dashboardVisible);
  };
  return (
    <section className="dark:bg-black md:py-8  sm:py-6 py-6 dark:text-white z-1000">
      <div className='flex flex-col items-center justify-center gap-4 z-1000 min-h-20 z-1000 px-6 py-8 rounded-lg shadow-lg dark:shadow-white shadow-black '>
        <div className='hidden lg:block z-100'>
          <Dashboard />
        </div>
        <div className='flex flex-col gap-4 items-center justify-center text-center lg:hidden rounded-lg py-2 px-4 shadow-lg z-10'>
          <img src="assets/dashboard/dashboard.png" className='w-full' />
          <button className='rounded-full p-4 bg-gray-400 dark:bg-gray-700 shadow hover:shadow-teal-400 dark:hover:shadow-orange-500'>
            <a href="https://public.tableau.com/app/profile/maxus.jaisi/viz/MalaysianEconomyLandscape/Dashboard1?publish=yes" target='_blank'>Tableau Dashboard</a>
          </button>
        </div>
        {/* <div className="flex flex-grow place-items-center">
          <AnimatedIconsList
            elements={dataAnalyticsIcons}
            element_number={dataAnalyticsIcons.length}
          />
        </div> */}
      </div>
    </section>
  )
}

export default DataAnalytics