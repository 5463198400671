import { configureStore } from '@reduxjs/toolkit'

import currentReducer from './counters/currentSlice'

// configuring store for Redux states and actions
export default configureStore({
  reducer: {
    // reducer can be named anything>>>> 
    current: currentReducer,
  }
})