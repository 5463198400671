import React from 'react';
import { motion } from 'framer-motion';
import Canvas from '../Canvas';

const Divider = () => {

    return (
        <section className="relative dark:bg-black dark:text-white lg:px-36 md:px-20 sm:px-12 px-8" id="about">
            <div class="flex flex-col place-content-center gap-3 lg:px-24 items-center justify-center">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="container gap-8 mt-4 z-10 py-6 text-white bg-gray-800 dark:bg-[#807A79] border shadow-lg dark:shadow-white shadow-black rounded-md">
                        <div className="flex flex-col justify-center gap-2">
                            <div className="text-3xl font-semibold text-center">
                                Digit Sum
                            </div>
                            <div className='p-2 md:text-lg text-md text-center'>Draw digits on the canvas and get the sum! <br></br>Something to entertain you 😃</div>
                        </div>
                        <Canvas height={150}  />
                </motion.div>
            </div>
        </section >
    )
}

export default Divider