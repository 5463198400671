import React from "react";
import "../css/Introduction.css"
import { FaLinkedin, FaGithub, FaWhatsapp, FaEnvelope } from 'react-icons/fa'
import CircularShadow from "../components/General/CircularShadow";
const Introduction = () => {

    return (
        // <section className="h-full p-24 bg-gradient-to-r from-white via-gray-500 to-blue-500 dark:from-black dark:to-red-500 bg-fixed bg-cover dark:bg-gray-800 py-16 background-gradient">
        <section className="grid lg:grid-cols-2 grid-cols-1 min-h-[80vh] gap-6 lg:gap-2 justify-along xl:px-56 lg:py-16 md:px-36 md:py-12 sm:px-12 sm:py-8 px-10 py-6 bg-white dark:bg-black" id="home">

            <div className="flex flex-shrink-0 absolute left-[-80px] md:left-[-122px] z-0">
                <div className="md:w-[500px] md:h-[500px] w-[250px] h-[250px] circle-shadow" />
            </div>
            {/* centers the content horizontally and adds some margin on the sides */}
            <div className="flex flex-wrap justify-along items-center border-top border-white z-0">
                {/* creates flex container and centers them vertically, adds padding to the left column on medium screens */}
                <div className="flex flex-col gap-2 md:gap-4 z-10 dark:text-white">
                    <div className="lg:text-4xl md:text-3xl sm:text-2xl text-xl z-10">
                        Hi there, I'm                    </div>
                    <div className="flex flex-row gap-3 text-5xl md:text-6xl lg:text-7xl font-bold z-10" >

                        <h1 className='text-light-primary dark:text-dark-primary'>Maxus</h1>
                        <h1 >Jaisi</h1>
                    </div>
                    <p className="w-3/4 lg:text-xl md:text-lg sm:text-md ">
                        A fresh graduate with experience in data engineering and web development.
                    </p>
                </div>
            </div>
            <div className="flex items-center justify-center lg:justify-end md:w-[full] shadow-teal-300 z-10">
                {/* <div className="absolute top-0 right-0 w-full h-full rounded-full overflow-hidden"> */}
                <img
                    src={'/assets/Maxus_Resume.JPEG'}
                    alt="Profile"
                    className="rounded-full w-60 h-60 md:w-80 md:h-80  object-cover"
                />
            </div>
        </section >
    );
};

export default Introduction;