import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch } from 'react-redux';
import { setCurrentSlide } from '../../counters/currentSlice';
const ImageSlider = ({ data }) => {
    const slideRef = useRef();
    const dispatch = useDispatch();

    const afterChangeHandler = (current, next) => {
        dispatch(setCurrentSlide(current));
    }

    useEffect(() => {
        // Create a new MutationObserver instance with a callback function
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    // Handle the DOM changes here (e.g., class changes)
                    console.log('Div has changed:', mutation);
                    // Add your logic to change the div as needed
                }
            }
        });


        // Start observing the div element
        observer.observe(slideRef.current, { childList: true });

        return () => {
            // Stop observing when the component unmounts
            observer.disconnect();
        };
    }, []);

    const sliderSettings = {
        // autoplay: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: afterChangeHandler,
    };

    return (
        <Slider {...sliderSettings}>
            {data.map((project, index) => (
                <div className='flex flex-col items-center justify-center' key={index} ref={slideRef}>
                    <img
                        src={project.imageUrl}
                        alt={project.title}
                        key={project.title}
                        className="mx-auto rounded-lg shadow-lg"
                    />
                    <div className='p-2'>
                        <p className="text-lg font-semibold mt-2">{project.title}</p>
                        <p className="dark:text-gray-300 text-gray-600 mt-2">{project.description}</p>
                    </div>
                </div>
            ))}
        </Slider>
    )
}

export default ImageSlider