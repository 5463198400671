import React, { useEffect } from 'react'
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux'
import { selectCurrentSlide } from '../counters/currentSlice'
import { ImageSlider } from '../components/modules';
import AnimatedIconsList from '../components/General/AnimatedIconsList';
import projects from '../data/data'
import icons from '../data/icons'
const WebApp = () => {
    const currentSlide = useSelector(selectCurrentSlide);

    const webProjects = projects.webProjects;
    const webIcons = icons.webIcons;

    useEffect(() => {
    }, [currentSlide]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="grid grid-cols-2 gap-8 mt-4 py-6">
            <div className="lg:col-span-1 col-span-2 ">
                <ImageSlider data={webProjects} />
            </div>
            <div className='lg:col-span-1 lg:mt-0 col-span-2 z-10 '>
                <div className="flex" >
                    {
                        currentSlide === 0 && (
                            <div className='flex items-center justify-center'>
                                <video className="rounded-lg w-1/2" controls autoplay >
                                    <source src="assets/website_carousel/gen_ai_stock.mp4" type="video/mp4" />
                                </video>
                            </div>
                        )
                    }
                    {
                        currentSlide === 1 && (
                            <div className='flex items-center justify-center'>
                                <video className="rounded-lg w-1/2" muted controls autoplay >
                                    <source src="assets/website_carousel/uber_app_demo.mp4" type="video/mp4" />
                                </video>
                            </div>
                        )
                    }
                    {
                        currentSlide === 2 && (

                            <video className="rounded-lg h-full" muted controls autoplay >
                                <source src="assets/website_carousel/first_assignment.mov" type="video/mp4" />
                            </video>
                        )
                    }
                </div>
            </div>

            <div className='col-span-2 mx-auto flex flex-col place-items-center justify-along'>
                {
                    <AnimatedIconsList
                        elements={webIcons[currentSlide]}
                        element_number={webIcons[currentSlide].length}
                    />

                }
            </div>
        </motion.div>
    );
}

export default WebApp