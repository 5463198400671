const data =
{
    machineLearningIcons: [
        [
            {
                name: 'docker',
                src: "https://www.docker.com/",
                color: 'orange',
                size: 40
            },
            {
                name: 'lambda',
                src: "https://aws.amazon.com/lambda/",
                color: 'orange',
                size: 40
            },
            {
                name: 'api-gateway',
                src: "https://aws.amazon.com/api-gateway/",
                color: 'red',
                size: 40
            },
            {
                name: 's3',
                src: "https://aws.amazon.com/s3/",
                size: 40,
            },
            {
                name: 'sagemaker',
                src: "https://aws.amazon.com/sagemaker/",
                size: 40,
            },
            {
                name: 'bedrock',
                src: "https://aws.amazon.com/bedrock/",
                size: 40,
            },
        ],
        [
            {
                name: 'python',
                src: "https://www.tensorflow.org/",
                color: 'orange',
                size: 40
            },
            {
                name: 'ros',
                src: "https://www.ros.org/",
                size: 40
            },
            {
                name: 'tensorflow',
                src: "https://www.tensorflow.org/",
                color: 'orange',
                size: 40
            },
            {
                name: 'opencv',
                src: "https://opencv.org/",
                color: 'red',
                size: 40
            },
            {
                name: 'matplotlib',
                src: "https://opencv.org/",
                color: 'red',
                size: 40
            },
            {
                name: 'numpy',
                src: "https://opencv.org/",
                color: 'red',
                size: 40
            },
        ],
        [
            {
                name: 'nextjs',
                src: "https://www.docker.com/",
                color: 'orange',
                size: 40
            },
            {
                name: 'docker',
                src: "https://www.docker.com/",
                color: 'orange',
                size: 40
            },
            {
                name: 'lambda',
                src: "https://aws.amazon.com/lambda/",
                color: 'orange',
                size: 40
            },
            {
                name: 'api-gateway',
                src: "https://aws.amazon.com/api-gateway/",
                color: 'red',
                size: 40
            },
            {
                name: 's3',
                src: "https://aws.amazon.com/s3/",
                size: 40,
            },
            {
                name: 'bedrock',
                src: "https://aws.amazon.com/bedrock/",
                size: 40,
            },
        ],

    ],
    dataAnalyticsIcons: [
        {
            name: 'amazon_s3',
            src: "https://aws.amazon.com/s3/",
            color: 'orange',
            size: 40
        },
        {
            name: 'amazon_redshift',
            src: "https://aws.amazon.com/redshift/",
            color: 'orange',
            size: 40
        },
        {
            name: 'tableau',
            src: "https://www.tableau.com/",
            color: 'blue',
            size: 40
        },
    ],
    webIcons: [
        [
            {
                name: 'react_native',
                src: "https://reactnative.dev/",
                color: 'white',
                size: 40
            },
            {
                name: 'firebase',
                src: "https://firebase.google.com/",
                color: 'white',
                size: 40
            },

            {
                name: 'google_maps',
                src: "https://developers.google.com/maps",
                color: 'white',
                size: 40
            },
            {
                name: 'react_navigation',
                src: "https://reactnavigation.org/",
                color: 'white',
                size: 40
            },
            {
                name: 'redux',
                src: "https://redux.js.org/",
                color: 'purple',
                size: 40
            },
            {
                name: 'tailwind',
                src: "https://tailwindcss.com/",
                color: 'white',
                size: 40
            },
        ],
        [
            {
                name: 'react_native',
                src: "https://reactnative.dev/",
                color: 'white',
                size: 40
            },
            {
                name: 'redux',
                src: "https://redux.js.org/",
                color: 'purple',
                size: 40
            },
            {
                name: 'firebase',
                src: "https://firebase.google.com/",
                color: 'white',
                size: 40
            },
            {
                name: 'openai',
                src: "https://openai.com/pricing",
                color: 'white',
                size: 40
            },
            {
                name: 'nodejs',
                src: "https://openai.com/pricing",
                color: 'white',
                size: 40
            },
            {
                name: 'fastapi',
                src: "https://openai.com/pricing",
                color: 'white',
                size: 40
            },

        ],
        [
            // coffee shop
            // LAMP stack
            {
                name: 'html',
                src: "https://www.php.net/",
                color: 'white',
                size: 40
            },
            {
                name: 'css',
                src: "https://www.php.net/",
                color: 'white',
                size: 40
            },
            {
                name: 'javascript',
                src: "https://www.javascript.com/",
                color: 'white',
                size: 40
            },
            {
                name: 'bootstrap',
                src: "https://getbootstrap.com/",
                color: 'white',
                size: 40
            },
            {
                name: 'google_maps',
                src: "https://developers.google.com/maps",
                color: 'white',
                size: 40
            },
            {
                name: 'mysql',
                src: "https://www.mysql.com/",
                size: 50,
            },
        ],

    ],

    stackIcons: [
        {
            name: 'python',
            src: "https://www.python.org/",
            size: 50,
        },
        {
            name: 'javascript',
            src: "https://www.javascript.com/",
            size: 50,
        },
        {
            name: 'c',
            src: "https://www.cprogramming.com/",
            size: 50,
        },
        {
            name: 'c++',
            src: "https://isocpp.org/",
            size: 50,
        },
        {
            name: 'mysql',
            src: "https://www.mysql.com/",
            size: 50,
        },
        {
            name: 'ros',
            src: "https://www.ros.org/",
            size: 50,
        },

    ],
}

export default data