const data = {
    education: [
        {
            type: 'internship',
            name: 'Roche Site Services & Solutions',
            level: "Informatics Intern",
            cgpa: "3.77 / 4.00",
            duration: "2023",
            imgSrc: '/assets/logo/roche-logo.png',
            imgSize: '80',
            imgHeight: '256',
            extras: [
                'Exposure to many different areas of the informatics business, including data engineering, and business analysis.',
                'Exposure to agile and scrum ways of working with tools such as Jira and Trello.',
                'Piloted an AI tool to be brought into Roche.',
                'Exposure to AWS Services tools.',
                'Presented the topic Generative AI to an audience of 300+.',
                
            ]
        },
        {
            type: 'bachelor',
            name: 'Xiamen University Malaysia',
            level: "BSc Computer Science and Technologies (CST)",
            cgpa: "3.77 / 4.00",
            duration: "2020 - 2024",
            imgSrc: 'assets/logo/xiamen-logo.png',
            imgSize: '64',
            imgHeight: '256',
            extras: [
                "CGPA: 3.75/4.00",
                "100% Merit Scholarship (Top 2)",
                "Chinese Ambassador Scholarship, selected by People's Embassy of China.",
                "Won prizes in corporate level hackathon and inter-university competitions.",
            ]
        },
        {
            type: 'foundation',
            name: 'Xiamen University Malaysia',
            level: "Foundation in Science",
            cgpa: "3.69 / 4.00",
            duration: "2019 - 2020",
            imgSrc: '/assets/logo/xiamen-logo.png',
            imgSize: '64',
            imgHeight: '256',
            extras: [
                "CGPA: 3.69/4.00",
                "90% Scholarship.",
            ]
        },
        
    ]
}

export default data;