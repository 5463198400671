import React, { useState, useEffect } from "react";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
const TextToSpeech = ({ text, disabled }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);

    setUtterance(u);

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;

    if (isPaused) {
      synth.resume();
    }

    synth.speak(utterance);

    setIsPaused(false);
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;

    synth.pause();

    setIsPaused(true);
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    synth.cancel();

    setIsPaused(false);
  };

  return (
    <div className='flex flex-row gap-2 h-full'>
      <button className='p-1 rounded-md hover:border  hover:border-gray-400' disabled={disabled} onClick={handlePlay}><FaMicrophone /></button>
      {/* <button className='p-1 rounded-md border border-gray-200 hover:border-gray-400' onClick={handleStop}><FaMicrophoneSlash /></button> */}
    </div>
  );
};

export default TextToSpeech;