import React, { useEffect } from 'react'
import { motion } from 'framer-motion';
import { Canvas } from '../components/modules';
import { ImageSlider } from '../components/modules';
import AnimatedIconsList from '../components/General/AnimatedIconsList';
import projects from '../data/data';
import icons from '../data/icons'
import { useSelector } from 'react-redux';
import { selectCurrentSlide } from '../counters/currentSlice';
import Chat from '../components/Chat';
const MachineLearning = () => {
    const currentSlide = useSelector(selectCurrentSlide);

    const mlProjects = projects.mlProjects;
    const mlIcons = icons.machineLearningIcons;

    useEffect(() => {
    }, [currentSlide]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="grid grid-cols-2 gap-8 mt-4 z-10 py-6">
            <div className="lg:col-span-1 col-span-2 ">
                <ImageSlider data={mlProjects} />
            </div>
            <div className='lg:col-span-1 lg:mt-0 col-span-2 z-10 '>
                <div className="flex" >
                    {
                        currentSlide === 0 && (
                            <Chat />
                        )
                    }
                    {
                        currentSlide === 1 && (
                            <video className="rounded-lg h-full" muted controls autoPlay >
                                <source src="assets/ml_carousel/tri-sensor-fusion.mp4" type="video/mp4" />
                            </video>
                        )
                    }
                    {
                        currentSlide === 2 && (
                            <video className="rounded-lg h-full" muted controls autoPlay >
                                <source src="assets/ml_carousel/bedrock_chat_example.mp4" type="video/mp4" />
                            </video>
                        )
                    }
                </div>
            </div>

            <div className='col-span-2 mx-auto flex flex-col place-items-center justify-along'>
                <AnimatedIconsList
                    elements={mlIcons[currentSlide]}
                    element_number={mlIcons[currentSlide].length}
                />
            </div>
        </motion.div>
    );
}

export default MachineLearning
