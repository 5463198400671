import React from 'react'

const Achievements = ({ type }) => {
    return (
        <div className='flex h-full'>
            {
                type.map((skills, index) =>
                <div className="flex md:px-12 md:py-6 py-3 px-2 relative h-full w-full"
                key={index}>
                        <img src={skills.imgSrc} alt="qualification-background" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full object-cover opacity-[10%] dark:filter dark:opacity-[20%] dark:invert"  />
                        <div className="flex flex-col min-h-[288px]" >
                            <h1 className="lg:text-2xl text-xl font-semibold">{skills.name}</h1>
                            <h5 className='lg:text-lg md:text-md float-right text-light-primary dark:text-dark-primary font-semibold text-md mt-2'>{skills.duration}</h5>
                            <h1 className="lg:text-lg md:text-md text-sm text-light-primary dark:text-dark-primary mt-2">{skills.level}</h1>
                            <ul className='lg:text-lg text-md text-gray-700 dark:text-gray-300 mt-2 marker:text-light-primary dark:marker:text-dark-secondary space-y-3 list-disc pl-5'>
                                {
                                    skills.extras.map((extra) =>
                                        <li key={extra}>{extra}</li>
                                    )}
                            </ul>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Achievements