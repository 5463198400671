import React, { useState, useRef, useEffect } from 'react'
const { tableau } = window;
const Dashboard = () => {
  const [iframeVisible, setIframeVisible] = useState(false);

  const openIframe = () => {
    setIframeVisible(true);
  };

  const closeIframe = () => {
    setIframeVisible(false);
  };

  const containerRef = useRef(null);
  useEffect(() => {
    const url = "https://public.tableau.com/shared/NM8PBWRDY?:display_count=n&:origin=viz_share_link";
    const options = {
      hideTabs: false,
      hideToolbar: false,
      // width: "290px",
      // height: "180px",
    };

    const viz = new tableau.Viz(containerRef.current, url, options);

    // Clean up when the component is unmounted
    return () => {
      viz.dispose();
    };
  }, []);

  return <div className='z-100' ref={containerRef} />


}

export default Dashboard